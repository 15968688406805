$cal-event-color-primary: $primary;
$cal-event-color-secondary: $secondary;
$cal-border-color: $border-color;
$cal-bg-primary: $white;
$cal-weekend-color: $body-color;
$cal-today-bg: $white;
$cal-badge-color: $primary;

@import '~angular-calendar/scss/angular-calendar.scss';

.cal-month-view {
    .cal-day-number {
        font-weight: $font-weight-bold;
        font-size: $font-size-base;
    }

    .cal-cell-row .cal-cell:hover, 
    .cal-cell.cal-has-events.cal-open {
        background-color: $dropdown-link-hover-bg;
    }

    .cal-cell-row:hover {
        background-color: $white;
    }

    .cal-day-cell {
        &.cal-today {
            .cal-day-number {
                font-size: $font-size-base;
                color: $primary;
                opacity: 1;
            }
        }
    }
    
    .cal-day-cell {
        min-height: 120px;
    }

    .cal-cell-row {
        .popover {
            max-width: 600px;
        }
    }

    .cal-event {
        width: 1rem;
        height: 1rem;
        border-radius: $border-radius;
    }
}

.cal-tooltip {
    opacity: 1;
}

.cal-tooltip-inner {
    box-shadow: 0px 1px 8px rgba(121, 149, 167, 0.2);
    background-color: $white;
    color: $body-color;
    max-width: 400px;
    padding: 0.75rem 1rem;
    text-align: left;
    border-radius: $border-radius;
    font-size: $font-size-base;
}