html {
    height: 100%;
    touch-action: manipulation;
}
  
html,
body,
app-root {
    // overflow-x: hidden;
    height: 100%;
}

:focus {
    outline: none;
}