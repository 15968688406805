.panel-group {

	.panel {
		&.card {
			box-shadow: none;
			border-bottom: 1px solid $border-color;
			margin-bottom: 0;
		}
	}

	.panel-heading  {
		&.card-header {
			border-bottom: 0;
			padding: 1rem 1.5rem;
		}
	}

	.panel-title {
		.accordion-toggle {
			.btn {
				padding: 0;

				&:focus {
					box-shadow: none;
				}
			}
			
			.btn-link {
				font-weight: $font-weight-bold;
				color: $gray-900;
				font-size: 1rem;
			}
		}
	}

	.panel-body {
		&.card-body {
			padding: 0.5rem 1.5rem 1rem 1.5rem;
		}
	}

	&.nested {
		>.card {
			margin-bottom: 0px;
			border-bottom: 0px;

			&:last-child {
				border-bottom: 1px solid $border-color;
			}
		}	
	}
}
